import 'moment/locale/en-gb';

export default {
  translation: {
    actions: {
      actions: 'Actions',
      save: 'Save',
      clear: 'Clear fields',
      edit: 'Edit',
      create: 'Create',
      cancel: 'Cancel',
      dismiss: 'Dismiss',
      unlinkDevice: 'Unlink device',
      disenroll: 'Disenroll',
      delete: 'Delete',
      confirmDelete: 'Confirm delete',
      confirmDeleteRow: 'Do you want to delete this row?',
      accept: 'Accept',
      search: 'Search',
    },
    types: {
      true: 'True',
      false: 'False'
    },
    home: {
      title: 'Welcome!'
    },
    login: {
      button_login: 'Login',
      document_id: 'Document number',
      password: 'Password',
      repeatPassword: 'Repeat password',
      company_code: 'Company code',
      userAccount: 'Current user account',
      dashboard: 'Performance panel',
      panelPermissionsDenied: 'You don\'t have permissions for Panel',
      forgotPassword: 'Forgot password?',
      iRememberMyPassword: 'I remember my password',
      backToLogin: 'Back to Login',
      recoverPasswordTitle: 'Recover your password',
      recoverPasswordButton: 'Recover password',
      recoverPasswordEmailSent: 'Check your inbox! If that account is in our system, we emailed you a link to reset your password.',
      changePasswordTitle: 'Change your password',
      changePasswordButton: 'Change password',
      changePasswordSubmitted: 'Your password has been updated',
      permissionsMissing: 'You don\'t have the required permissions to access',
    },
    menu: {
      areas: 'Areas',
      absences: 'Absences',
      admin: 'Administration',
      settings: 'Settings',
      records: 'Records',
      performance: 'Performance panel',
      locations: 'Locations',
      notifications: 'Notifications',
      notifications_out_of_range: 'Incidents',
      notifications_random_check: 'Random check',
      day_off_type: 'Day off types',
      day_off: 'Absences',
      collaborator: 'Collaborators',
      daily_clock: 'Daily clocks',
      calendar: 'Calendar',
      log: 'Logs',
      company: 'Company',
      companyAdministrator: 'Admin',
      performance_check: 'Daily clocks',
      daily_clock_notification: 'Daily Clock Notification',
      logs: 'Logs',
      companies: 'Companies',
      administrators: 'Administrators',
      holiday: 'Holidays',
      nonWorkingDays: 'Non working day',
      cafeteriaCalendar: 'Cafeteria',
    },
    area: {
      name: 'Name',
      enabled: 'Enabled',
      create: 'Create',
      edit: 'Edit area {{name}}',
      yes: 'Yes',
      no: 'No',
    },
    daily_clock_notification: {
      id: 'ID',
      distance: 'Distance',
      employee_id: 'Employee',
      daily_clock_id: 'Daily clock',
      company_id: 'Company',
      title: 'Daily Clock Notification',
      date: 'Date',
      coordinates: 'Coordinates',
      open_daily_clock: 'Open daily clock',
      employee: 'Employee',
      spoofedPicture: 'Spoofed'
    },
    random_daily_clock_notification: {
      message: 'Message',
      lateBy: 'Delayed time',
      date: 'Date',
      employee_id: 'Employee',
      open_daily_clock: 'Open daily clock',
    },
    office: {
      name: 'Name',
      address: 'Address',
      suburb: 'Suburb',
      city: 'City',
      password: 'Password',
      country: 'Country',
      zipCode: 'Zip Code',
      location: 'Location',
      companyId: 'Company',
      employeeId: 'Employee',
      create: 'Create Location',
      edit: 'Edit Location "{{name}}"',
      successfullyCreated: 'Office has been successfully created',
      successfullyUpdated: 'Office has been successfully updated',
      successfullyDeleted: 'Office has been successfully deleted',
      generateNewPassword: 'Generate new password',
      company: 'Company',
    },
    collaborator: {
      export: 'Export collaborators',
      role: 'Role',
      collaborator: 'Collaborator',
      area: 'Area',
      companyAdmin: 'Administrator',
      picture: 'Picture',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      deviceId: 'Device id',
      approvalDate: 'Approval date',
      active: 'Active',
      superior: 'Superior',
      withPerformance: 'Performance panel',
      create: 'Create Collaborator',
      emailVerified: 'Email verified',
      edit: 'Edit Collaborator "{{name}}"',
      document: 'Document',
      nationalId: 'National ID',
      legajo: 'Internal number',
      password: 'Password',
      repeatPassword: 'Repeat password',
      homeOfficeAllowed: 'Home office allowed',
      address: 'Address',
      successfullyCreated: 'Collaborator has been successfully created',
      successfullyUpdated: 'Collaborator has been successfully updated',
      successfullyDeleted: 'Collaborator has been successfully deleted',
      successfullyDisenrolled: 'Collaborator has been successfully disenrolled',
      deviceSuccessfullyUnlinked: 'Device has been successfully unlinked',
      fileImported:
        'File Succesfully imported. Inserted: {{inserted}} | \n Already exists: {{exists}} | \n Failed: {{failed}}',
      import: 'Import Collaborators',
      templateName: 'Collaborator import template',
      downloadTemplate: 'Download template',
      homeOffice: 'Home office address',
      birthDate: 'Birth date',
      citizenship: 'Citizenship',
      floor: 'Floor',
      apartment: 'Apartment',
      phoneNumber: 'Phone number',
      admissionDate: 'Admission date',
      confirmNewTabChange: 'There are unsaved changes, you will loose your changes if you move to another tab',
      confirmNewTab: 'Form with changes',
      personalDetails: 'Personal details',
      absences: 'Absences',
      calendars: 'Calendars',
      holidays: 'Holidays',
      dailyClocks: 'Daily clocks',
    },
    holidays: {
      addItem: 'Add holiday row',
      start: 'START',
      end: 'END',
      days: 'DAYS',
      status: 'STATUS',
      pending: 'PENDING',
      ended: 'ENDED',
      inProgress: 'IN PROGRESS',
      successfullyUpdated: 'Holidays have been successfully updated',
      successfullyDeleted: 'Holidays have been successfully deleted',
      fileImported: 'File Succesfully imported. Inserted: {{inserted}} | \n Already exists: {{exists}} | \n Failed: {{failed}}',
    },
    calendar: {
      create: 'Create calendar',
      edit: 'Edit calendar {{name}}',
      hourHasToBeMinorThan24: 'Hours has to be minor or equal than 23',
      minutesHasToBeMinorThan59: 'Minutes has to be minor or equal than 59',
      startHour: 'Hour',
      startMinute: 'Minute',
      endHour: 'Hour',
      endMinute: 'Minute',
      pending: 'Pending',
      ended: 'Ended',
      inProgress: 'In progress',
      addItem: 'Add item',
      name: 'Name',
      type: 'Type',
      calendarId: 'Calendar',
      calendarName: 'Calendar Name',
      types: {
        weekly: 'Weekly',
        fortnight: 'Biweekly',
        flexible: 'Flexible',
        default: 'Company',
      },
      totalWorkHours: 'Total Work Hours',
      companyId: 'Company',
      active: 'Active',
      activeSince: 'Active since',
      activeUntil: 'Active until',
      fieldTooLong: 'Field has to be minor than 100 characters',
      successfullyCreated: 'Calendar has been successfully created',
      bulkSuccessfullyUpdated: 'Calendars have been successfully updated',
      successfullyUpdated: 'Calendar has been successfully updated',
      successfullyDeleted: 'Calendar has been successfully deleted',
      dateIsEmpty: 'This time has to be completed',
      startDateIsGrater: 'End time has to be major than start time',
      startDateIsEqual: 'These times has to be different',
      days: 'Days',
      day: 'Day',
      start: 'Start',
      end: 'End',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
      totalHoursNotEqual:
        'Total hours from periods ({{period}} hours) are different from total hours in the calendar ({{calendar}} hours)',
      week1: 'First week',
      week2: 'Second week',
      fileImported:
        'File Succesfully imported. Inserted: {{inserted}} | \n Already exists: {{exists}} | \n Failed: {{failed}}',
      import: 'Import Collaborator Calendars',
      templateName: 'Calendar import template',
      downloadTemplate: 'Download template',
      activeSinceNotEditable: 'You cannot modify start date once created',
      activeStatusNotEditable: 'You cannot change active status if end date is overdue'
    },
    administrator: {
      create: 'Create Administrator',
      edit: 'Edit Administrator "{{name}}"',
      document: 'Document',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      password: 'Password',
      repeatPassword: 'Repeat password',
      company: 'Company',
      picture: 'Picture',
      isCollaborator: 'Is Collaborator?',
      isCollaboratorDescription: 'Collaborators can perform Checks In/Out',
      successfullyCreated: 'Administrator has been successfully created',
      successfullyUpdated: 'Administrator has been successfully updated',
      successfullyDeleted: 'Administrator has been successfully deleted',
      successfullyDisenrolled: 'Administrator has been successfully disenrolled',
      collaboratorData: 'Collaborator data',
    },
    dayOffType: {
      name: 'Name',
      payable: 'Payable',
      category: 'Category',
      enabled: 'Enabled',
      edit: 'Edit Day Off Type',
      categories: {
        normal: 'Normal',
        sickness: 'Sickness',
        other: 'Other',
        vacations: 'Vacations'
      },
      successfullyUpdated: 'Day off type has been successfully updated'
    },
    dayOff: {
      date: 'Date',
      create: 'Crear Absence',
      edit: 'Editar Absence {{type}}',
      files: 'Files',
      approvalDate: 'Approval Date',
      rejectedDate: 'Rejected Date',
      approvedBy: 'Action By',
      company: 'Company',
      employee: 'Collaborator',
      reason: 'Reason',
      start: 'Start',
      end: 'End',
      type: 'Type',
      importVacations: 'Import Vacations',
      templateName: 'Vacations import template',
      successfullyCreated: 'Absense has been successfully created',
      successfullyUpdated: 'Absense has been successfully updated',
      successfullyDeleted: 'Absense has been successfully deleted',
      approveSuccessfully: 'Absense has been successfully approve',
      rejectSuccessfully: 'Absence has been successfully rejected'
    },
    dailyClock: {
      type: 'Type',
      cafeteriaType: 'Cafeteria',
      types: {
        in: 'In',
        out: 'Out',
        randomCheck: 'Random',
        cafeteria: 'Cafeteria',
      },
      maxDateMessage: 'Date cannot be earlier than current value',
      minDateMessage: 'Date cannot be later than current value',
      date: 'Date',
      time: 'Time',
      picture: 'Picture',
      homeOffice: 'Home Office',
      userLocation: 'Location',
      location: 'Office/HO',
      address: 'Check address',
      viewInMap: 'View location in Map',
      latitude: 'Latitude',
      longitude: 'Longitude',
      employee: 'Collaborator',
      recordType: 'Audit',
      recordTypes: {
        AdminCreateAction: 'Created by admin',
        AdminEditAction: 'Edited by admin',
        System: 'System action',
        UndoOnBoardingCheck: 'Undo onboarding check',
        Mobile: 'Mobile check'
      },
      company: 'Company',
      create: 'Create Check',
      edit: 'Edit Check {{type}}',
      successfullyCreated: 'Check in/out has been successfully created',
      successfullyUpdated: 'Check in/out has been successfully updated'
    },
    log: {
      ipAddress: 'IP Address',
      entity: 'Entity',
      entityId: 'Entity ID',
      actionType: 'Action',
      userId: 'User',
      companyId: 'Company',
      createdAt: 'Created At',
      previousData: 'Before',
      updatedData: 'After'
    },
    company: {
      create: 'Create Company',
      edit: 'Edit Company "{{name}}"',
      successfullyCreated: 'Company has been successfully created',
      successfullyUpdated: 'Company has been successfully updated',
      successfullyDeleted: 'Company has been successfully deleted',
      minTimeNotification: 'Min time notification',
      maxTimeNotification: 'Max time notification',
      checkInTime: 'Check in time'
    },
    settings: {
      name: 'Name',
      logo: 'Logo',
      photo: 'Photo',
      mainColor: 'Main color',
      accentColor: 'Accent color',
      distanceRange: 'Distance range (Home office)',
      defaultLanguage: 'Default language',
      english: 'English',
      spanish: 'Spanish',
      registrationCode: 'Registration code',
      homeOfficeMode: 'Home office mode',
      normal: 'Normal',
      intense: 'Intense',
      none: 'None',
      businessName: 'Business name',
      responsible: 'Responsible',
      email: 'Email',
      taxId: 'Tax ID',
      phone: 'Phone',
      address: 'Address',
      taxAddress: 'Tax address',
      bank: 'Bank',
      bankAccount: 'Bank account',
      active: 'Active',
      invoiceTypeId: 'Invoice type',
      edit: 'Settings',
      successfullyUpdated: 'Settings have been successfully updated',
      dropzoneDetails: 'File has to be between 1MB and 5MB',
      minTimeNotification: 'Min time notification',
      maxTimeNotification: 'Max time notification',
      checkInTime: 'Countdown timer',
      intenseTooltip: 'This mode will ask user to perform random checks during day',
      minTimeNotificationTooltip: 'Minimum time since checkin to generate a random push notification',
      maxTimeNotificationTooltip: '‘Maximum time since checkin to generate a random push notification',
      checkInTimeTooltip: 'Countdown timer to perform the control check',
      fasActive: 'Anti Spoofing enabled',
      fasThreshold: 'Threshold',
      fasStrict: 'Strict Mode',
      ifSpoofedSave: 'Save'
    },
    validations: {
      required: 'Required',
      missingFields: 'All fields are required',
      minLength: 'Must be {{min}} characters or more',
      maxLength: 'Must be {{max}} characters or less',
      exactLength: 'Must be {{length}} characters',
      number: 'Must be a number',
      minValue: 'Must be at least {{min}}',
      maxValue: 'Must be less than {{max}}',
      email: 'Invalid email address',
      color: 'Invalid color',
      validText: 'Contains invalid characters',
      address: 'Invalid address',
      passwordsMatch: "Passwords don't match",
      startDateIsGrater: 'Start date has to be minor than end date',
      approvalDateIsGrater: 'Approval date has to be greater or equal than today',
      invalidDate: 'Invalid date.',
      invalidTime: 'Invalid time.',
      minTimeIsGreater: 'Max time has to be major than min time',
      equalTimes: 'Min and Max times must be different',
      hourLowerThan24: 'Has to be minor or equal to 23',
      minuteLowerThan59: 'Has to be minor or equal to 59',
      greaterThan0: 'Has to be greater than 0',
      dateNotEditable: "You cannot modify DailyClock date, only hours are editable",
      dateLowerToday: 'Date must be lower than today',
      dateTimeLowerNow: 'Datetime must be lower than now',
      dateGreaterToday: 'Date must be greater than today',
    },
    dropzone: {
      attention: 'Attention!',
      text: 'Drop image here or press the button to upload the image',
      dropFilesHere: 'Drop files here...',
      tooMuchFiles: `File quantity exceeded`,
    },
    menuDashboard: {
      dashboard: 'Dashboard',
      team: 'Team',
      people: 'People',
      report: 'Report',
      profile: 'Profile',
    },
    table: {
      title: 'Delete rows.',
      text: 'Do you want to delete the selected rows?',
      rowsPerPage: 'Rows per page',
      of: 'of',
      noData: 'No data available',
      filters: 'Filters',
    },
    nonWorkingDay: {
      create: 'Create Non Working Day',
      edit: 'Edit Non Working Day {{title}}',
      title: 'Title',
      description: 'Description',
      start: 'Start',
      end: 'End',
      category: 'Category',
      allDay: 'All day',
      day: 'Day',
      PublicHoliday: 'Public holiday',
      BusinessClose: 'Business close',
      successfullyCreated: 'Non Working has been successfully created',
      successfullyUpdated: 'Non Working has been successfully updated',
      successfullyDeleted: 'Non Working successfully deleted',
    },
    cafeteriaCalendar: {
      create: 'Create Cafeteria Shift',
      edit: 'Edit Cafeteria Shift {{name}}',
      name: 'Name',
      start: 'Start',
      end: 'End',
      enabled: 'Enabled',
      successfullyCreated: 'Cafeteria Shift has been successfully created',
      successfullyUpdated: 'Cafeteria Shift has been successfully updated',
      successfullyDeleted: 'Cafeteria Shift successfully deleted',
    },
    performance: {
      id: 'ID',
      checkIn: 'Check In',
      checkOut: 'Check Out',
      actions: 'Actions',
      myTeam: 'My team',
      name: 'Name',
      reason: 'Reason',
      directBoss: 'Direct boss',
      me: 'Me',
      team: 'Team',
      all: 'All',
      absences: 'Absences',
      time: 'Time',
      area: 'Area',
      hoursReport: 'Hours Report',
      extraHours: 'Extra hours',
      missingHours: 'Missing hours',
      expectedCheckIn: 'Expected CheckIn',
      expectedCheckOut: 'Expected CheckOut',
      arrivedLate: 'Arrived Late',
      leftEarly: 'Left Early',
      checkInOut: 'Check in / Check out',
      cafeteria: 'Cafeteria',
      realTimeAssistance: 'Assistance in real time',
      onTime: 'On time',
      late: 'Late',
      absent: 'Absent',
      todayCheck: 'Check In / Check Out of today',
      reportedAbsences: 'Reported absences for today',
      unreportedAbsences: 'Absences not reported for today',
      dailyClocks: 'Checks',
      filterBy: 'Filter by',
      month: 'Month',
      currentMonth: 'Current month',
      oneMonth: 'Last month',
      twoMonths: 'Last 2 months',
      threeMonths: 'Last 3 months',
      dates: 'Dates',
      fromDate: 'From',
      toDate: 'To',
      week: 'Week',
      today: 'Today',
      day: 'day',
      days: 'days',
      noData: 'No data yet',
      type: 'Type',
      notAssigned: 'No Area',
      reports: {
        download: 'Download',
        downloadAll: 'Download all',
        downloadSelected: 'Download selected',
        from: 'From',
        to: 'To',
        orderBy: 'Order By',
        dateAsc: 'Date Ascending',
        dateDesc: 'Date Descending',
        nameAsc: 'Name Ascending',
        nameDesc: 'Name Descending',
        date: 'Date',
        expectedWorkHours: 'Work Hours',
        totalWorkedHours: 'Worked Hours',
        dataError: "Ups! There is no report data to display for collaborator/s",
      },
      none: 'None',
      profile: {
        personalDataTitle: 'My Personal Information',
        address: 'Add',
        phone: 'Phone',
        email: 'E-mail',
        myCalendarTitle: 'My Calendar',
        myHOTitle: 'My Home Office Address',
        myVacationsTitle: 'My Vacations',
        availableVacations: 'AVAILABLE DAYS',
      },
      dashboard: {
        attend: 'Attended',
        absence: 'Absense',
        late: 'Late',
        homeOffice: 'Home Office',
        inSite: 'In Site',
        directBoss: 'Direct superior',
        calendarTitle: 'CALENDAR',
        checkInOutTitle: 'CHECK IN/CHECK OUT',
        checkInTitle: 'CHECK IN',
        checkOutTitle: 'CHECK OUT',
        attendanceVsAbsences: 'Attendance vs Absences',
        homeOfficeVsInsite: 'Home office vs Insite',
        hoursMissedDueToLateArrivals: 'Hours missed due to late arrivals',
        hours: 'Hours',
        averageWorkday: 'Average workday',
        absencesQty: 'Absences quantity',
        days: 'Days',
        totalWorkedHoursVsExpectedWorkHours: 'Worked hours vs work hours',
      },
    },
    roles: {
      collaborator: 'Collaborator',
      companyAdministrator: 'Administrator',
    },
    filters: {
      all: 'All',
      team: 'Team',
      peopleFilter: 'Filter by',
    },
  },
};
